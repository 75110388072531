.work-desc {
    width: 80%;
    text-align: left;
    justify-content: center;

}
.work-div {
    word-wrap: break-word;
}
img{
    position: sticky;
}
.img-container {
    position: sticky;
}
@media only screen and (max-width: 1200px){
    .work-div {
        margin-right: 10%;
    }
    .img-container {
        width: 60%;
    }
}
@media only screen and (min-width: 660px){
    .work-div {
        width: 40%;
    } 
    img{
        padding: 2rem;
    }
    .img-container {
        width: 15%;
    }
}

@media only screen and (min-width: 660px) and (max-width: 1200px) {
    .work-div {
        width: 40%;
    } 
    .img-container {
        width: 30%;
    }
}
